import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GetSingleStudent } from '../../../services/query/students';
import "./style.scss";

export function RedirectPage() {
  const [page, setPage] = useState([<p>Redirecting page...</p>]);
  const search = new URLSearchParams(useLocation().search);
  const id = search.get('id');
  const navigate = useNavigate();

  const errorPage = () => {
    return (
      <div className='center-item'>
        <p>Email verification link has expired.</p>
      </div>
    )
  }

  useEffect(() => {
    const handleSetDependencies = async () => {
      const resp = await fetch(`${process.env.REACT_APP_NODE_SERVER}/youth/decode-token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: id })
      }) 
      if (resp.status === 400) {
        setPage(errorPage);
      } else {
        const respData = await resp.json();
        const student = await GetSingleStudent(respData.studentId);
        navigate(`/verify?id=${student.id}&name=${student.name}&email=${student.email}&parentEmail=${respData.email}`);
      }
    }

    handleSetDependencies();
  }, [id]);

  return ( 
    <>{page}</>
  );
}
