import { ChevronLeft } from 'react-bootstrap-icons';
import ButtonWithIcon from '../../widgets/ButtonWithIcon/ButtonWithIcon';
import { Link } from 'react-router-dom';
import './style.scss'

export function PageNotFoundPage() {
  return ( 
    <div className="page_not_found">
      <div>
        <div 
          style={{fontSize: 35}}
          className="bold"
        >
          Oops 
        </div>
        <div style={{fontSize: 24}}>
          Page not found
        </div>
        <p>
          This Page doesn`t exist or was removed!
          We suggest you  back to home.
        </p>
        <Link to="/opportunities">
          <ButtonWithIcon
            text="Back to explore"
            icon={<ChevronLeft/>}
            type="primary align_left"
          />
        </Link>
      </div>
      <div>
        <img 
          src={
            require('../../assets/images/404.png')
          }
          alt=""
        />
      </div>
    </div>
  );
}