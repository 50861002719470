import { StarFill } from "react-bootstrap-icons";
import React, { useState, useEffect } from 'react';


function ReviewStarFill({starNo}) {
  starNo = starNo || 0

  const [filled, setFilled] = useState([])
  const [unfilled, setUnFilled] = useState([])

  const starFilled = (key) => {
    return (
      <StarFill fill="#FFC768" key={key}/>
    )
  }

  const starUnFilled = (key) => {
    return (
      <StarFill fill="#DDDFE2" key={key}/>
    )
  }

  useEffect(() => {
    let collectFills = []
    let collectUnFills = []

    for (let i = 0; i < parseInt(starNo); i++){
      collectFills.push(starFilled(`filled${i}`))
    }

    for (let i = 0; i < 5 - parseInt(starNo); i++){
      collectUnFills.push(starUnFilled(`unfilled${i}`))
    }

    setFilled(collectFills)
    setUnFilled(collectUnFills)

  }, [starNo])
  
  return ( 
    <>
      <>{filled}</>
      <>{unfilled}</>
    </>
  );
}

export default ReviewStarFill;