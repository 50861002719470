/* eslint-disable no-unused-expressions */
import { API } from "aws-amplify";

export const GET_ALL_STUDENTS = `
  query AllStudents {
    listStudents (limit: 1000) {
      items {
        email
        name
        state
        id
        educatorID
        dob
        gender
        phone
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        address
        balance
        apartment
        graduation_year
        graduation_term
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        is_verified
        school
        schooling_mode
        profile_sharing
        employed
        current_employer
        current_occupation
      }

      nextToken
    }
  }
`;

export const GET_ALL_STUDENTS_WITH_TOKEN = `
  query StudentsQuery ($limit: Int, $nextToken: String) {
    listStudents (limit: $limit, nextToken: $nextToken) {
      items {
        email
        name
        state
        id
        educatorID
        dob
        gender
        phone
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        address
        balance
        apartment
        graduation_year
        graduation_term
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        is_verified
        school
        schooling_mode
        profile_sharing
        employed
        current_employer
        current_occupation
      }
      nextToken
    }
}
`;

export async function AllStudents() {
  const resp = await API.graphql({
    query: GET_ALL_STUDENTS,
  });

  const data = resp?.data?.listStudents;
  let nextToken = data?.nextToken;
  let students = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_ALL_STUDENTS_WITH_TOKEN,
      variables: { limit: 1000, nextToken },
    });
    const respData = resp?.data?.listStudents;

    const previousData = [...students, ...respData?.items];
    students = [...previousData];

    nextToken = respData?.nextToken;
  }

  return students || [];
}

export const GET_ALL_STUDENT_BY_EMAIL = `
  query AllStudents($email: AWSEmail) {
    listStudents (limit: 1000, filter: {email: { eq: $email }}) {
      items {
        email
        name
        state
        id
        educatorID
        dob
        gender
        phone
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        address
        balance
        apartment
        graduation_year
        graduation_term
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        is_verified
        school
        schooling_mode
        profile_sharing
        employed
        current_employer
        current_occupation
      }
    }
  }
`;

export async function GetStudentByEmail(email) {
  const students = await AllStudents();
  const student = students.find((student) => student.email === email);
  return student || {};
}

export const GET_STUDENT = `
  query SingleStudent($id: ID!) {
    getStudent(id: $id) {
      email
      name
      state
      id
      educatorID
      dob
      gender
      phone
      points
      schoolID
      updatedAt
      zipcode
      createdAt
      address
      balance
      apartment
      graduation_year
      graduation_term
      is_verified
      verification_date_started
      verification_progress
      verification_progress_dates
      verification_status
      schooling_mode
      school
      profile_sharing
      current_employer
      current_occupation
      employed
    }
  }
`;

export async function GetSingleStudent(id) {
  try {
    const respStudent = await API.graphql({
      query: GET_STUDENT,
      variables: { id },
      cache: true,
    });
    const student = respStudent?.data?.getStudent;
    return student;
  } catch (err) {
    console.error(err);
    return {};
  }
}
