import React from 'react'
import { loadStripe } from '@stripe/stripe-js';
import Button from '../../../widgets/Button/Button';


class VerifyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    this.setState({ stripe: await this.props.stripePromise });
  }

  async handleClick(event) {
    // Block native event handling.
    event.preventDefault();

    const { stripe } = this.state;

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      this.props.setLoading(false);

      return;
    }
    this.props.setLoading(true);
    // Call your backend to create the VerificationSession.
    const response = await fetch(
      `${process.env.REACT_APP_NODE_SERVER}/youth/create-verification-session`, 
      { 
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          'userId': this.props.studentID,
          name: this.props.studentName,
          email: this.props.email,
          parent: this.props.name,
          parentEmail: this.props.parentEmail
        }),
      }
    );
    const session = await response.json();

    const { error } = await stripe.verifyIdentity(session.client_secret);

    if (error) {
      console.log('[error]', error);
    } else {
      this.props.setSubmitted(true);
    }
  };

  render() {
    const { stripe } = this.state;
    return (
      <div className="mt-20">
        <Button
          text={this.props.loading ? "Loading..." : "Next"} 
          type="primary"
          onClick={this.handleClick}
          className={(!this.props.name || !stripe) ? 'disabled' : ''}
        />
      </div>
    );
  }
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PRIMARY_KEY);

const StripeVerificationButton = ({ studentID, name, email, parentEmail, studentName, setSubmitted, loading, setLoading }) => {
  return (
    <VerifyButton 
      stripePromise={stripePromise} 
      studentID={studentID}
      name={name}
      email={email}
      parentEmail={parentEmail}
      studentName={studentName}
      setSubmitted={setSubmitted}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default StripeVerificationButton;