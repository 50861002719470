

export const calculateTime = (date) => {
  if (date) {
    const now = new Date()
    const previous = new Date(date)
    if (now.getFullYear() === previous.getFullYear()){
      if (now.getMonth() === previous.getMonth()){
        const res = now.getDate() - previous.getDate()
        return `${res} ${res === 1 ? "day" : "days"} ago`
      }
      else {
        const res = now.getMonth() - previous.getMonth()
        return `${res} ${res === 1 ? "month" : "months"} ago`
      }
    }
    else {
      const monthInterval = (12 - previous.getMonth()) + now.getMonth();
      if (monthInterval < 12 ){
        return `${monthInterval} ${monthInterval === 1 ? "month" : "months"} ago`
      }
      const res = now.getFullYear() - previous.getFullYear()
      return `${res} ${res === 1 ? "year" : "years"} ago`
    }
  }
}

export const formatDate = (date) => {
  if (date) {
    const newDate = new Date(date);
    const monthStr = `${newDate}`.split(" ")[1];
    return monthStr + " " + newDate.getDate() + ", " + newDate.getFullYear();
  }

  return "Not specified";
};

export const getReviews = (reviews, opp, type) => {
  const filteredReviews = reviews?.filter((elt) => elt.resourceID === opp?.resourceID); 
  if (type === "length") return filteredReviews?.length;
  else {
    let sum = 0;
    filteredReviews?.forEach((elt) => {
      sum += parseInt(elt.stars);
    });
    const overallStars = parseFloat(sum / (reviews ? reviews?.length : 1));
    return Math.round(overallStars * 10) / 10
  }
};

export const getInitial = (name) => {
  if (name) {
    let initials = ""
    const splittedName = name.split(" ")
    splittedName.forEach(elt => {
      if (elt.length)
        initials += elt[0].toUpperCase()
    })
    return initials
  }
  return ""
}

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getReviewsAverage = (reviews) => {
  let averages = []
  if (reviews?.length){
    let initCount = 0;
    for (let i = 0; i < reviews.length; i++)
      initCount += reviews[i]?.stars
    averages.push(initCount/reviews?.length)
  }
  else {
    averages.push(0.0)
  }
  return averages.reduce((a, c) => a + c)/averages.length
}