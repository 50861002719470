import React, { useRef, useState, useEffect, useContext } from 'react';
import { GeoAlt } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom"
import ReviewStarFill from '../../widgets/ReviewStarFill/ReviewStarFill';
import { AllSchools } from '../../services/query/school';
import { GetReviewsByResourceID } from '../../services/query/review';
import { getReviewsAverage } from '../../utils/helpers';
import { FilterContext } from '../common/ExplorePagesWrapper';

export const ResourcesComponent = (props)=>{
  const {show} = {...props}
  const [resources, setResources] = useState([])
  const [reviews, setReviews] = useState({})
  const [filterResources, setFilterResources] = useState([])
  const {searchFilters} = useContext(FilterContext) || {
    'locations': [],
    'search': ''
  }
  const {locations, search} = searchFilters 
  const cardsGridRef = useRef(null)
  const navigate = useNavigate()

  const handleRoute = (resourceID) => {
    navigate(`/resource-profile?id=${resourceID}`)
  }

  useEffect(() => {
    if (resources){
      setFilterResources(resources);
      for (const element of resources){
        GetReviewsByResourceID(element?.id)
        .then((data)=>{
          setReviews({...reviews, [element.id]: data})
        })
      }
    }
  }, [resources]);

  useEffect(() => {
    if (locations.length){
      let filtered = resources.filter((elt) => locations.some(state => elt?.address?.toLowerCase().includes(state.toLowerCase())));
      setFilterResources(filtered);
    }
    else setFilterResources(resources)
  }, [locations])

  useEffect(() => {
    if (search){
      let nameSearch = resources.filter((elt) => elt?.name?.toLowerCase().includes(search.toLowerCase()));
      let emailSearch = resources.filter((elt) => elt?.contact_email?.toLowerCase().includes(search.toLowerCase()));
      let filtered = Array.from(new Set([...nameSearch, ...emailSearch]))
      setFilterResources(filtered);
    }
    else {
      setFilterResources(resources)
    }
  }, [search])
  
  useEffect(() => {
    AllSchools('resource').then((data) => {
      setResources(data); 
      setFilterResources(data); 
    });
  }, []);

  return ( 
    <div>
      <div 
          className="w-100 cards_grid resources large d-flex custom-flex-wrap gap-2 justify-content-center justify-content-sm-start"
          ref={cardsGridRef}
        >
          {filterResources && filterResources.map((resource, index) => 
            <div 
              className='opportunity-card cursor-pointer' 
              key={resource?.id}
              onClick={()=> handleRoute(resource?.id)}>
              <div className="d-flex align-items-start gap-2">
                <img 
                    src={resource?.image ? resource?.image : require("../../assets/images/microsoft.png")}
                    alt={resource?.name}
                    style={{height: 40, width:40}}
                    className='rounded-circle object-fit-cover'
                  />
                <div className="d-flex flex-column">
                  <h6 className='resource-name'>
                    {resource?.name}
                  </h6>
                  <h6 className='resource-category'>
                    {resource?.contact_email}
                  </h6>
                </div>  
              </div>
              <div className="flex-start">
                <div 
                  className='bold mr-10' 
                  style={{fontSize: 16}}
                >
                  {reviews[resource?.id]?.length ? getReviewsAverage(reviews[resource?.id]) : '0.0'}
                </div>
                <div className='d-flex align-items-center gap-1'>
                  <ReviewStarFill starNo={reviews[resource?.id]?.length}/>
                </div>
                <div className='align-items'>
                  ({reviews[resource?.id]?.length || 0} Reviews)
                </div>
              </div>
              <div className='w-100'>
                <div className="d-flex align-items-center my-3 resource-location">
                  <GeoAlt size={16} fill="#969CA5"/>
                  <div className="ml-10">
                    {resource?.state || resource?.address || "Hartford, CT"}
                  </div>
                </div>
                <div className="w-100 pt-3 d-flex align-items-center resource-connections border-top">
                  <div className='rounded-pill resource-connections-icon'>
                    <i className='fas fa-chain fa-fw text-white'></i>
                  </div>
                  <div className="align-items ml-10">
                    Connected to
                    <span className='ps-1 resource-connections-count'>
                      {resource?.connections?.length || 0} Youths
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}