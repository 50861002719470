import { Modal } from "react-bootstrap";
import { 
  Globe, 
  X,
  BoxArrowUp
} from "react-bootstrap-icons";
import ButtonWithIcon from "../../widgets/ButtonWithIcon/ButtonWithIcon";


function ApplyYouthModal(props) {
  const { setShow, ...resprops } = {...props}
  return ( 
    <Modal
      {...resprops}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="apply_youth_modal"
    >
      <Modal.Body>
        <div className="modal_top_bg">
          <div className="flex-end">
            <div 
              className="close_btn cursor-pointer"
              onClick={() => setShow(false)}
            >
              <X size={24}/>
            </div>
          </div>
        </div>
        <div className="modal_content pd-20">
          <div className="d-flex justify-content-center justify-content-sm-between custom-flex-wrap gap-3">
            <div className="flex-v justify-center">
              <div 
                style={{
                  fontSize: 25, 
                  color: "#2E394C"
                }} 
                className="bold"
              >
                Login As A Youth On Mobile Device
              </div>
              <div className="globe_link_wrapper mt-10">
                <a
                  href="https://youth.joinjunity.com"
                  className="btn btn-warning border-dashed w-100 d-flex justify-content-center align-items-center gap-2"
                  style={{borderRadius: '32px'}}
                >
                  <Globe/>
                  https://youth.joinjunity.com
                </a>
              </div>
            </div>
            <div>
              <img 
                src={
                  require("../../assets/images/barcode.png")
                }
                alt=""
              />
            </div>
          </div>
          <div className="mt-20" style={{color: "#090B0F"}}>
            Adding Junity to the Home Screen makes 
            it easier for you to access Junity. Junity will just be a tap away
          </div>
          <div className="mt-20">
            <div className="flex-start">
              <div>
                <div className="item_no">1</div>
              </div>
              <div className="ps-3">
                Tap the <BoxArrowUp size={20} fill={"#007AFF"}/> icon at the bottom of your
              </div>
            </div>
            <div className="flex-start mt-20">
              <div>
                <div className="item_no">2</div>
              </div>
              <div className="ps-3">
                Scroll down the pop-up menu till 
                you see <b className="pe-1">Add to home screen</b>
                and hit add.
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ApplyYouthModal;