import React from 'react'
import PageTemplate from '../../templates/PageTemplate'
import { ResourcesComponent } from '../../components/Resources'
import { ExplorePagesWrapper } from '../../components/common/ExplorePagesWrapper';

export const ResourcesPage = ()=>{
  return (
    <PageTemplate>
      <ExplorePagesWrapper>
        <ResourcesComponent/>
      </ExplorePagesWrapper>
    </PageTemplate>
  )
}
