import React from 'react'
import { BadgeInput } from './BadgeInput'
import { useLocation, useNavigate } from 'react-router-dom'

export const NavigationBadges = ()=>{
  const pathname = useLocation().pathname.split('/')[1]
  const navigate = useNavigate()

  const setPage = (path)=>{
    navigate(path)
  }
  return (
    <div 
      className="d-flex custom-flex-wrap gap-2 px-3 px-sm-0">
      <BadgeInput
        name={"route"}
        value={"opportunities"}
        label={"Opportunities"}
        checked={pathname === "opportunities" || pathname === ""}
        clickable={true}
        onClick={()=>{setPage('/opportunities')}}
      />
      <BadgeInput
        name={"route"}
        value={"resources"}
        label={"Resource Providers"}
        checked={pathname === "resources"}
        clickable={true}
        onClick={()=>{setPage('/resources')}}
      />
      <BadgeInput
        name={"route"}
        value={"mentors"}
        label={"Mentors"}
        checked={pathname === "mentors"}
        clickable={true}
        onClick={()=>{setPage('/mentors')}}
      />
    </div>
  )
}
