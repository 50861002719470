import React from 'react';

export const BadgeInput = ({
  name, 
  value, 
  label, 
  type="warning", 
  checked=false, 
  clickable=false,
  onClick=()=>{}})=>{

  return (
    <label className={`badge-radio badge-radio-${type} ${clickable ? 'clickable': ''} mb-0 mr-1`}>
      <input 
        type={'radio'} 
        name={name} 
        value={value} 
        className="d-none"
        onChange={onClick}
        checked={checked}
        />
      <span className="btn btn-outline-light">{label}</span>
    </label>
  )
}