import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from "react-bootstrap";

export const CustomSelect = ({name, title, items=[], multiple=false, objectKey="", onChange= ()=>{}})=>{
  const [selected, setSelected] = useState(null);
  const [label, setLabel] = useState(title)

  const handleSelect = (item)=>{
      if (multiple){
        if (Array.isArray(selected)){
            setSelected(prev => [...prev, item])
        }
        else {
            setSelected([])
        }
      }
      else {
        setSelected(item)
        setLabel(item)
      }
  }

  useEffect(()=>{
    if (selected !== null){
      onChange(name, selected)
    }
  }, [selected])


  return (
    <DropdownButton title={label} className="opp_drops dropdown-btn">
      {items?.map((elt, index) => 
        <Dropdown.Item 
          onClick={() => handleSelect(typeof elt === 'string' ? elt : elt?.name)} 
          key={typeof elt === 'string' ? `elt-${index}` : elt[objectKey]}>
            {typeof elt === 'string' ? elt : elt?.name}
        </Dropdown.Item>
      )}
    </DropdownButton>
)}