import { Routes, Route } from "react-router-dom";
import { 
  PageNotFoundPage,
  ParentalVerificationPage, 
  RedirectPage,
  YouthDesktopLogin,
  OpportunitiesPage,
  ResourcesPage,
  MentorsPage,
  ResourceProfilePage,
  MentorsProfilePage,
} from "./pages";

import './App.scss';

function App() {
  return (
    <Routes>
      <Route path='/' exact element={<OpportunitiesPage/>}/>
      <Route path='/verify' exact element={<ParentalVerificationPage/>}/>
      <Route path='/404' element={<PageNotFoundPage/>}/>
      <Route path='/redirect' element={<RedirectPage/>}/>
      <Route path='/login-desktop' element={<YouthDesktopLogin/>}/>
      <Route path='/opportunities' element={<OpportunitiesPage/>}/>
      <Route path='/opportunities/:id' element={<OpportunitiesPage/>}/>
      <Route path='/resources' element={<ResourcesPage/>}/>
      <Route path='/mentors' element={<MentorsPage/>}/>
      <Route path='/resource-profile' element={<ResourceProfilePage/>}/>
      <Route path='/mentor-profile' element={<MentorsProfilePage/>}/>
    </Routes>
  );
}


export default App;
