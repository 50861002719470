import QRCode from "react-qr-code";
import { 
  Globe, 
  BoxArrowUp
} from "react-bootstrap-icons";
import ButtonWithIcon from "../../widgets/ButtonWithIcon/ButtonWithIcon";

import './style.scss';

export function YouthDesktopLogin() {
  return ( 
    <div className="youth-login-wrapper">
      <div className="qr-code-sect flex-center">
        <div className="qr-code">
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={'https://youth.joinjunity.com/login'}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
      <div className="content-details flex-center">
        <div className="">
          <div className="flex-v justify-center">
            <h4>
              Login As A Youth On Mobile Device
            </h4>
            <div className="globe_link_wrapper mt-10">
              <ButtonWithIcon
                text="youth.joinjunity.com"
                icon={<Globe/>}
                type="light_yellow_btn align_left long"
              />
            </div>
          </div>
        </div>
        <div className="mt-20">
          <h6 className="sub-header-text mt-20">
            Adding Junity to the Home Screen makes 
            it easier for you to access Junity. Junity will just be a tap away
          </h6>
          <div className="flex-start installation-guide">
            <div>
              <div className="item_no">1</div>
            </div>
            <div className="align-items push_item">
              Tap the
              &nbsp;
              <BoxArrowUp size={20} fill={"#007AFF"}/>
              &nbsp;
              icon at the bottom of your screen
            </div>
          </div>
          <div className="flex-start mt-20">
            <div>
              <div className="item_no">2</div>
            </div>
            <div className="align-items push_item">
              Scroll down the pop-up menu till 
              you see &nbsp;
              <b>“Add to home screen”</b>
              &nbsp;
              and hit add.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}