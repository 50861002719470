import React, { useState, useEffect } from 'react';

import { GetOpportunityBySchoolID } from '../../../services/query/opportunities';
import ShareModal from '../../../components/Opportunities/ShareModal';
import OpportunitiesComponent from '../../../components/Opportunities';
import "./style.scss"

function OpportunitiesTab({resourceID}) {
  const [shareModal, setShareModal] = useState(false)
  const [routes, setRoutes] = useState({
    opportunities: true
  })
  const [showInput, setShowInput] = useState(true)

  return ( 
    <div className="opportunities_tab">
      <div className="public_opportunity">
        <div className="main_section mt-20">
          <div className="section_main mt-20">
            <OpportunitiesComponent
              setShareModal={setShareModal}
              show={routes.opportunities}
              showInput={showInput}
              setShowInput={setShowInput}
              resourceID={resourceID}
            />
            {/* <ResourcesMain
              show={routes.resources}
            /> */}
          </div>
        </div>
        <ShareModal
          show={shareModal}
          onHide={() => setShareModal(false)}
        />
      </div>
    </div>
  );
}

export default OpportunitiesTab;