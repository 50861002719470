import React, {useState} from 'react';
import { calculateTime, getReviews } from '../../utils/helpers';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import ReviewStarFill from '../../widgets/ReviewStarFill/ReviewStarFill';
import { Upload } from 'react-bootstrap-icons';
import ShareModal from '../Opportunities/ShareModal';

export const OpportunityCard = ({opp, index, handleSelect = ()=>{}})=>{
  const [shareModal, setShareModal] = useState(false);

  return (
    <>
      <div className="opportunity-card mt-3 cursor-pointer">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <h6 
            className='opportunity-name cursor-pointer'
            onClick={() => handleSelect(index, opp)}
          >{opp?.name}</h6>
          <DropdownButton 
            title={<i className="fas fa-ellipsis-v"></i>} 
            className="opp_drops three_dots"
          >
            <Dropdown.Item 
              onClick={() => setShareModal(true)}
              className="d-flex align-items-center gap-2 px-3"
            >
              <Upload/>
              Share
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <h6 
          className="opportunity-description m-0 mt-1 cursor-pointer"
          onClick={() => handleSelect(index, opp)}
        >
          {opp?.description?.substring(0, 65)}...
        </h6>
        <div className='mt-2'>
          <button
            type="button"
            className="opportunity-tag-badge"
          >
            {opp?.category}
          </button>
        </div>
        <h6 className="font_card m-0 mt-2 opportunity-detail-posted" style={{ fontSize: 12 }}>
          <i className='fas fa-calendar-alt fa-fw'></i> 
          Posted {calculateTime(opp?.createdAt)}
        </h6>
        
        <div className='w-100 border-top pt-3'>
          <div className='d-flex align-items-center'>
            <img 
              src={opp?.school?.image || require("../../assets/images/microsoft.png")}
              className="rounded-circle object-fit-cover"
              style={{width: 40, height: 40}} 
              alt={opp?.school?.name}
            />
            <div className="align-items ml-10">
              {opp?.school?.name}
            </div>
          </div>
          <div className='d-flex custom-flex-wrap mt-2'>
            <div className='d-flex align-items-center gap-1'>
              <ReviewStarFill starNo={getReviews(opp?.reviews, opp, 'stars')}/>
            </div>
            <div className='opportunity-reviews-count'>
              ({getReviews(opp?.reviews, opp, 'length')} Reviews)
            </div>
          </div>
        </div>
      </div>
      <ShareModal
        show={shareModal}
        oppTitle={opp?.name}
        oppId={opp?.id}
        onHide={() => setShareModal(false)}
      />
    </>
  )
}