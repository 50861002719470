import { API } from "aws-amplify";
import { GetEducatorsBySchoolID } from "./educators";

export const GET_SCHOOLS = `
  query AllSchools ($type: String) {
    listSchools (limit: 1000, filter: {type: {eq: $type}}) {
      items {
        contact_email
        contact_phone
        createdAt
        updatedAt
        id
        name
        state
        address
        type
        image
        website
        zipcode
        connections
        type
        about
        connection_dates
        dont_show_again
      }

      nextToken
    }
  }
`;

export const GET_SCHOOLS_WITH_TOKEN = `
  query SchoolsQuery ($limit: Int, $nextToken: String) {
    listSchools (limit: $limit, nextToken: $nextToken) {
      items {
        contact_email
        contact_phone
        createdAt
        updatedAt
        id
        name
        state
        address
        image
        type
        website
        zipcode
        connections
        type
        about
        connections
        connection_dates
        dont_show_again
      }
      nextToken
    }
}
`;

export async function AllSchools(type="mentor") {
  const resp = await API.graphql({
    query: GET_SCHOOLS,
    variables: { type },
    cache: true
  });
    
  const data = resp?.data?.listSchools;
  let nextToken = data?.nextToken;
  let schools = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_SCHOOLS_WITH_TOKEN,
      variables: { limit: 1000, nextToken },
    });
    const respData = resp?.data?.listSchools;

    const previousData = [...schools, ...respData.items];
    schools = [...previousData];

    nextToken = respData?.nextToken;
  }

  return await schools?.reduce(async (previousPromise, school) => {
    const educators = await GetEducatorsBySchoolID(school.id);
    let schoolsData = await previousPromise;
    const obj = { ...school, educators };
    
    schoolsData.push(obj);
    return schoolsData;
  }, Promise.resolve([]));
}

export async function GetSchoolsByState(state) {
  const schools = await AllSchools();
  const sameStateSchools = schools?.filter((school) => school.state === state);
  return sameStateSchools || [];
}

export const GET_SCHOOL = `
  query SingleSchool($id: ID!) {
    getSchool(id: $id) {
      contact_email
      contact_phone
      createdAt
      id
      name
      image
      state
      address
      updatedAt
      zipcode
      website
      connections
      type
      about
      type
      connection_dates
      dont_show_again
    }
  }
`;

export async function GetSingleSchool(id) {
  try {
    const respSchool = await API.graphql({
      query: GET_SCHOOL,
      variables: { id },
      cache: true
    });
    const school = respSchool?.data?.getSchool;
    return school;
  } catch (err) {
    return {};
  }
}
