import { API } from "aws-amplify"
import { AllInterests } from "./interests";

export const GET_CATEGORIES = `
  query CategoriesQuery {
    listCategories (limit: 1000) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
}
`

export const GET_CATEGORIES_WITH_TOKEN = `
  query CategoriesQuery ($limit: Int, $nextToken: String) {
    listCategories (limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
}
`

export async function AllCategories() {
  const resp = await API.graphql({ 
    query: GET_CATEGORIES,
  });

  const data = resp?.data?.listCategories
  let nextToken = data?.nextToken
  let categories = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_CATEGORIES_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listCategories

    const previousData = [...categories, ...respData?.items]
    categories = [...previousData];
    nextToken = respData?.nextToken
  }
  
  return categories || [];
}

export const GET_CATEGORY = `
  query SingleCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export async function GetSingleCategory(id) {
  try {
    const respCategory = await API.graphql({
      query: GET_CATEGORY,
      variables: { id },
    });
    const category = respCategory?.data?.getCategory;
    return category;
  } catch (err) {
    console.error(err);
    return {};
  } 
}

export const GET_CATEGORY_BY_NAME = `
  query SingleCategoryByName($name: String!) {
    listCategories(filter: {name: { eq: $name }}) {
      items {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export async function GetSingleCategoryByName(name) {
  const categories = await AllCategories();
  const category = categories.find(c => c.name === name);
  return category || {};
}

export async function GetCategoryWithInterests() {
  const categories = await AllCategories();
  return await categories.reduce(async(previousPromise, category) => {
    const interests = await AllInterests();
    const categoryInterests = interests?.filter((elt) => elt.categoryID === category.id);
    const obj = { ...category, interests: categoryInterests }
    const categoryData = await previousPromise;
    categoryData.push(obj);
    return categoryData;
  }, Promise.all([]));
}
