import './style.scss'

function ButtonWithIcon({
  text, 
  icon, 
  className='',
  style={},
  onClick=()=>{}
}) {

  return ( 
    <button 
      className={`d-flex align-items-center gap-2 btn btn-outline-${className} fw-bold`}
      style={style}
      onClick={onClick}
    >
      {icon}
      <span>{text}</span>
    </button>
  );
}

export default ButtonWithIcon;