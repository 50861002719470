import { ReactComponent as FacebookLogo } from "../../assets/images/facebook.svg"
import { ReactComponent as LinkedinLogo } from "../../assets/images/linkedin.svg"
import { ReactComponent as TwitterLogo } from "../../assets/images/twitter.svg"


function SocialMediaTab({ shareUrl, title }) {
    const shareToLinkedIn = () => {
        const url = window.location.href + "/" + shareUrl;
        // Open the LinkedIn share dialog
        const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`;
        window.open(linkedinUrl, "_blank");
    }
  return ( 
    <div className="flex-between">
      <div className="logo_wrapper">
        <div className="flex-center">
          <TwitterLogo/>
        </div>
        <div className="text-center">
          Twitter
        </div>
      </div>
      <div className="logo_wrapper">
        <div className="flex-center">
          <FacebookLogo/>
        </div>
        <div className="text-center">
          Facebook
        </div>
      </div>
      <div className="logo_wrapper" onClick={shareToLinkedIn}>
        <div className="flex-center">
          <LinkedinLogo/>
        </div>
        <div className="text-center">
          LinkedIn
        </div>
      </div>
    </div>
  );
}

export default SocialMediaTab;