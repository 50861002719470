import React from 'react'
import PageTemplate from '../../templates/PageTemplate'
import { MentorsComponent } from '../../components/Mentors'
import { ExplorePagesWrapper } from '../../components/common/ExplorePagesWrapper';

export const MentorsPage = ()=>{
  return (
    <PageTemplate>
      <ExplorePagesWrapper>
        <MentorsComponent/>
      </ExplorePagesWrapper>
    </PageTemplate>
  )
}
