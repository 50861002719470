import React, {useEffect, useState} from 'react';
import { StarFill } from "react-bootstrap-icons";
import ReviewStarFill from "../../../widgets/ReviewStarFill/ReviewStarFill";
import { ProgressBar } from "react-bootstrap";
import InitialsWrapper from "../../../widgets/InitialsWrapper/InitialsWrapper";
import { GetReviewsByResourceID, AllReviews } from "../../../services/query/review";
import { GetSingleStudent } from '../../../services/query/students';
import { formatDate, getInitial, getRandomColor } from '../../../utils/helpers';

const ReviewCardComponent = ({review})=>{
  const [student, setStudent] = useState({})

  useEffect(() => {
    GetSingleStudent(review?.studentID)
    .then(data => {
      if (data) setStudent(data[0])
    })
  }, [])
  
  return (
    <div className="mt-20 right_card">
      <div className="flex-start">
        <div>
          <InitialsWrapper
            text={getInitial(student?.name)}
            bg={getRandomColor()}
            type={'md'}
          />
        </div>
        <div className="mh-10">
          <h6 className='review-date m-0'>
            {formatDate(review?.createdAt)}
          </h6>
          <ReviewStarFill starNo={review?.stars}/>
        </div>
      </div>
      <p className="mt-10 review-description">
        {review?.description || review?.review}
      </p>
    </div>
  )
}

const ReviewTab = ({resourceID})=>{
  const [reviews, setReviews] = useState([])
  const [selectedReview, setSelectedReview] = useState([])
  const [reviewsAverage, setReviewsAverage] = useState(0.0)
  const [starsPoints, setStarsPoints] = useState({
    5: [],
    4: [],
    3: [],
    2: [],
    1: []
  })

  useEffect(() => {
    GetReviewsByResourceID(resourceID)
    .then(data => {
      setReviews(data)
      setSelectedReview(data)

      let averages = []
      for (let key in starsPoints){
        let numPerReview = data?.filter(rev => rev?.stars === Number.parseInt(key))
        setStarsPoints({...starsPoints, [key]: numPerReview})

        if (numPerReview?.length){
          let initCount = 0;
          for (let i = 0; i < numPerReview.length; i++)
            initCount += numPerReview[i]?.stars
          averages.push(initCount/numPerReview?.length)
        }
        else {
          averages.push(0.0)
        }
        
      }
      setReviewsAverage(averages.reduce((a, c) => a + c)/averages.length)
    })
  }, [])
  
  return ( 
    <div className="row">
      <div className="col-sm-5 col-md-4 mt-3">
        <div className="flex-start">
          <div 
            style={{fontSize: 20}}
            className="bold mr-10"
          >
            {reviewsAverage.toFixed(1)}
          </div>
          <div className="align-items">
            <ReviewStarFill starNo={reviews?.length}/>
          </div>
        </div>
        <div>
          ({`${reviews?.length} ${reviews?.length === 1 ? 'Review' : 'Reviews'}`})
        </div>
        <div>
          {Object.keys(starsPoints).reverse().map((key, index) =>
          <div 
            className={`flex-between align-items-center pd-10 mt-10 ${starsPoints[key] === selectedReview ? "review_active" : ""}`}
            key={index}
            onClick={() => setSelectedReview(starsPoints[key])}
          >
            <div className="d-flex gap-2 align-items-center">
              <div className="align-items">
                {key}
              </div>
              <div className="align-items me-1">
                <StarFill fill={starsPoints[key] === selectedReview ? "#806334" : "#FFC768"}/>
              </div>
            </div>
            <div 
              className="flex-start align-items-center"
              style={{width: "87%"}}
            >
              <div className="progress_bar_wrapper">
                <ProgressBar now={starsPoints[key].length ? parseInt((reviews.length / starsPoints[key].length)*100) : 0}/>
              </div>
              <div>{starsPoints[key].length}</div>
            </div>
          </div>
          )}
        </div>
      </div>
      <div className="col-sm-7 col-md-8 mt-3">
        <div style={{fontSize:20}}>
          Reviews({selectedReview?.length})
        </div>
        <div>
          {reviews.map((review, index) =>
            <ReviewCardComponent review={review} key={review?.id}/>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReviewTab;