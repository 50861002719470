import './style.scss'

function Button(props) {

  const { 
    text, 
    type, 
    className,
    onClick
  } = {...props}

  return ( 
    <div 
      className={`btn_wrapper ${className} ${type}`}
      onClick={onClick}
    >
      <input 
        type="button" 
        value={text} 
        disabled={className === 'disabled'}
      />
    </div>
  );
}

export default Button;