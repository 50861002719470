import { BoxArrowUpRight } from 'react-bootstrap-icons';
import ReviewStarFill from '../../widgets/ReviewStarFill/ReviewStarFill';
import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import './style.scss'
import OverviewTab from './OverviewTab/overviewTab';
import ReviewTab from './OverviewTab/reviewTab';
import OpportunitiesTab from './OverviewTab/opportunitiesTab';
import { Link, useLocation } from 'react-router-dom';
import PageTemplate from '../../templates/PageTemplate';
import {axiosClient} from "../../libs/axiosClient";
import {getReviewsAverage} from "../../utils/helpers";

export function ResourceProfilePage() {
  const search = new URLSearchParams(useLocation().search)
  const resourceID = search.get('id')
  const [key, setKey] = useState('overview');
  const [school, setSchool] = useState({});

  const getOrganization = async () => {
    try {
      const resp = await axiosClient.post('/organization', {
        schoolId: resourceID,
        fields: ["image", "name", "address", "website"]
      });

      setSchool(resp.data.data);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (resourceID){
      getOrganization()
    }
  }, [resourceID])

  return ( 
    <PageTemplate>
      <div className="resource_profile">
        <div className="resource_profile_main">
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex align-items-start gap-2">
              <img
                src={school?.image || require("../../assets/images/microsoft.png")
                }
                alt={school?.name}
                style={{height: 42, width: 42}}
                className='object-fit-cover rounded-circle'
              />
              <div className="d-flex flex-column">
                <h5 className='opportunity-detail-owner m-0'>
                  {school?.name}
                </h5>
                <h5 className='opportunity-detail-state'>
                  {school?.address?.split(',')[1] || ""}, CT
                </h5>
              </div>
              <div className="ml-10 resources_review_div flex-v">
                <div className="flex-start">
                  <div 
                    style={{fontSize: 18}}
                    className="bold mr-10"
                  >
                    {getReviewsAverage(school?.reviews)?.toFixed(1)}
                  </div>
                  <div className="align-items">
                    <ReviewStarFill starNo={school?.reviews?.length}/>
                  </div>
                </div>
                <div>
                  ({school?.reviews?.length} Reviews)
                </div>
              </div>
            </div>
            {school?.website
            ?
            (<a 
              href={school?.website}
              className="btn-website cursor-pointer"
              target={'_blank'}
              rel='noreferrer'
              >
                <BoxArrowUpRight/>
                Website 
              </a>)
              
            :
            (
              <Link 
              to={'/resources'}
              className="btn-website"
            >
              <BoxArrowUpRight/>
              Website 
            </Link>)
            }
            
          </div>
          <div className="resources_review_div_mob mt-10 flex-v">
            <div className="flex-start">
              <div 
                style={{fontSize: 18}}
                className="bold mr-10"
              >
                {getReviewsAverage(school?.reviews)?.toFixed(1)}
              </div>
              <div className="align-items">
                <ReviewStarFill starNo={school?.reviews?.length}/>
              </div>
            </div>
            <div>
              ({school?.reviews?.length} Reviews)
            </div>
          </div>
          <div className="mt-40">
            <Tabs
              id="share_modal"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="overview" title="Overview">
                <OverviewTab resource={school}/>
              </Tab>
              <Tab eventKey="reviews" title="Reviews">
                <ReviewTab resourceID={resourceID}/>
              </Tab>
              <Tab eventKey="opportunities" title="Opportunities">
                <OpportunitiesTab resourceID={resourceID}/>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}