import React, { useState, useEffect } from 'react';
import { ArrowRight } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';


function NavLinksModal(props) {
  const {show, setShow} = {...props}
  const [fullscreen, setFullscreen] = useState(true);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const handleClose = () => {
    // setExplore(true)
    setShow(false)
  }

  return (
    <Modal 
      show={show} 
      fullscreen={fullscreen} 
      onHide={() => setShow(false)}
      className="mobile_nav_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className='flex-between'>
            <div className="top_section__img">
              <img src={
                require("../../../assets/images/user_logo.png")
              }
              alt=""
              />
            </div>
            <div className="top_section__heading align-items">
              <span>Junity</span>
              <sup>TM</sup>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='nav_links_wrapper'>
          <div>
            <div style={{color: "#B9BDC3",fontSize: 18}}>
              Explore
            </div>
            <div className="nav_links">
              <div onClick={handleClose}>
                <Link to="/opportunities">
                  Opportunities
                </Link>
              </div>
              <div onClick={handleClose}>
                <Link to="/resources">
                  Resource Providers
                </Link>
              </div>
              <div onClick={handleClose}>
                <Link to="/mentors">
                  Mentors
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div 
              style={{
                color: "#B9BDC3",
                fontSize: 18
              }}
            >
              Login
            </div>
            <div className="nav_links bottom_links">
              <div>
                <a href={process.env.REACT_APP_YOUTH_URL} className="flex-between text-white">
                  As a Youth
                  <div className='align-items'>
                    <ArrowRight/>
                  </div>
                </a>
              </div>
              <div>
                <a href={process.env.REACT_APP_RESOURCES_URL} className="flex-between text-white">
                  As a Resource Provider
                  <div className='align-items'>
                    <ArrowRight/>
                  </div>
                </a> 
              </div>
              <div>
                <a href={process.env.REACT_APP_MENTORS_URL} className="flex-between text-white">
                  As a Mentor 
                  <div className='align-items'>
                    <ArrowRight/>
                  </div>
                </a> 
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default NavLinksModal;