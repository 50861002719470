import React, { useEffect, useState } from 'react'
import "./style.scss"
import { Link } from "react-router-dom";
import { OpportunityCard } from '../../../components/common/Cards';
import {axiosClient} from "../../../libs/axiosClient";

function OverviewTab({resource}) {
  const [opps, setOpps] = useState([])

  const getOpportunities = async () => {
    try {
      const resp = await axiosClient.post("/opportunities", {
        limit: 20,
        review: true,
        fields: [
          "id",
          "description",
          "name",
          "category",
          "createdAt",
          "resourceID",
          "schoolID",
          "state",
          "expiry_date"
        ]
      });
      const data = resp?.data?.data;
      setOpps(data);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getOpportunities()
  }, [resource])
  
  return ( 
    <>
      <div className="resource_overview_tab">
        <h6 className="resource-welcome-message m-0">
          Welcome to {resource?.name}
        </h6>
        <p className="mt-10 resource-detail-description">
          {resource?.about}
        </p>
        <div className="main_content mt-40">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="heading">
              Top Opportunities
            </h6>
            <Link
              to={'/opportunities'}
              className={'link-show-all'}
            >
              Show all
            </Link>
          </div>
          <div 
            className="d-flex custom-flex-wrap gap-2 justify-content-center justify-content-md-start"
          >
            {opps?.map((opp, index) => 
              <OpportunityCard
                opp={opp}
                index={index}
                key={opp?.id}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default OverviewTab;