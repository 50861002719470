/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import StripeVerificationButton from './StripeButton';
// import { CloudUpload, ExclamationCircle } from 'react-bootstrap-icons';
// import Button from '../../widgets/Button/Button';
// import ButtonWithIcon from '../../widgets/ButtonWithIcon/ButtonWithIcon';
// import NotificationWithIcon from '../../widgets/NotificationWithIcon/NotificationWithIcon';
import PageTemplate from '../../templates/PageTemplate';
import './style.scss'

export function ParentalVerificationPage() {
  const search = new URLSearchParams(useLocation().search);
  const [name, setName] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const id = search.get("id");
  const studentName = search.get("name");
  const email = search.get("email");
  const parentEmail = search.get("parentEmail");
  const navigate = useNavigate();

  useEffect(() => {
    submitted ? navigate('/opportunities'): '';
  }, [submitted]);
  

  return (
    <PageTemplate>
      <div className="flex-center">
        <div className="parental_verification_page">
          <div className="top_section">
            <h1 className='bold'>Start Verification</h1>
            <p>
              Your ward, <span className='color_primary bold'>{studentName}</span>, 
              needs your verification to use Junity. Please provide the 
              following information so we may verify your consent
              and verification for your child to use our platform.
            </p>
          </div>
          <div className='main_content mt-20'>
            <div className='mt-20'>
              <h6 className='input_title'>Your Full Name</h6>
              <Form.Control
                placeholder=""
                name="Parent Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {/* <div className="mt-20">
              <h6 className='input_title'>Your Identification</h6>
              <p className='input_title_sub'>
                Upload a copy of your valid government-issued ID to confirm your 
                identity (e.g., driver's license, passport, birth certificate, etc.)
              </p>
              <div className='mt-20'>
                <ButtonWithIcon
                  text="Upload image"
                  type="secondary align_left"
                  icon={<CloudUpload/>}
                />
              </div>
              <div className='mt-20'>
                <p className='input_title_sub'>
                  Accepted formats are PNG, JPG, GIF, or WEBP              
                </p>
                <p className='input_title_sub'>
                  Your identification must be uploaded as a single file.              
                </p>
              </div>
            </div>
            <div className="mt-20">
              <h6 className='input_title'>Proof of Guardianship</h6>
              <p className='input_title_sub'>
                Upload documentation authorizing you to act on behalf of your 
                child’s behalf (e.g. power of attorney, birth certificate, 
                documents showing parental/guardianship rights of minor children). 
                Please upload a legible copy with your child’s full name and date of 
                birth completely visible.
              </p>
              <div className='mt-20'>
                <ButtonWithIcon
                  text="Upload image"
                  type="secondary align_left"
                  icon={<CloudUpload/>}
                />
              </div>
              <div className='mt-20'>
                <p className='input_title_sub'>
                  Accepted formats are PNG, JPG, GIF, or WEBP              
                </p>
                <p className='input_title_sub'>
                  Your identification must be uploaded as a single file.              
                </p>
              </div>
              <div className='mt-20'>
                <NotificationWithIcon
                  text="Your uploaded documents will be kept 
                        confidential, and will be used strictly 
                        to verify you and your child’s identity."
                  icon={<ExclamationCircle size={23}/>}
                />
              </div>
            </div> */}
            <div className='mt-20'>
              <h6 className='input_title'>Your Declaration</h6>
              <div>
                <div className='flex-evenly'>
                  <div className='align-items mr-10'>
                    <Form.Check checked/>
                  </div>
                  <p>
                    I confirm that I hold parental responsibility 
                    over the child that is using this account.
                  </p>
                </div>
                <div className='flex-evenly'>
                  <div className='align-items mr-10'>
                    <Form.Check checked/>
                  </div>
                  <p>
                    I have reviewed my child’s account and hereby allow Junity to use my child’s 
                    information, in line with Junity’s <span className="color_primary">Terms of Service</span>
                    , and <span className="color_primary">Privacy Policy</span>. 
                    I understand that I can later withdraw this verification by deleting my
                    child’s account.
                  </p>
                </div>
              </div>
            </div>
            <StripeVerificationButton 
              studentID={id}
              setSubmitted={setSubmitted}
              name={name}
              email={email}
              studentName={studentName}
              parentEmail={parentEmail}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}