import React, { useState, useEffect, useRef } from 'react'
import Select from "react-select";
import { Form } from 'react-bootstrap'
import { City } from "country-state-city";
import { GeoAlt, Search } from 'react-bootstrap-icons'
import { useLocation } from 'react-router-dom'
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();


export default function SearchSection({onLocationChange=()=>{}, onSearch=()=>{}}) {
  const [places, setPlaces] = useState([]);
  const path = useLocation().pathname
  const searchExcluded = ['/resources', '/mentors']
  const searchInput = useRef(null);
  const modifiedStates = [];

  const usCities = City.getCitiesOfState('US', 'CT');

  usCities.forEach((elt) => {
    modifiedStates.push({ label: `${elt.name}, CT`, value: `${elt.name}, CT` });
  })

  useEffect(() => {
    onLocationChange(places?.map((elt) => elt.value));
  }, [places])

  const handleSearch = ()=>{
    onSearch(searchInput.current.value)
  }

  return (
    <div className="main_section mt-20">
      <div className="section_header flex-between align-items-start gap-4">
        <div className="mr-10" style={{width: 400, maxWidth: '100%'}}>
          <label className='custom-form-label mb-2'>Location(s)</label>
          <Select
              closeMenuOnSelect={false}
              className="grayed"
              isOptionDisabled={() => places.length > 0 && places.length === 5}
              components={animatedComponents}
              isMulti
              options={modifiedStates}
              isClearable={true}
              placeholder="Start typing..."
              name="state"
              value={places}
              onChange={(e) => setPlaces(e)}
          />
        </div>
        {!searchExcluded.includes(path) &&
        <div className='d-flex justify-content-end'>
          <div className="mr-10" style={{width: 400, maxWidth: '100%'}}>
            <label className='custom-form-label mb-2'>What are you looking for </label>
            <Form.Control ref={searchInput}
              placeholder="Role, title, job description"
              className='custom-form-input'
            />
          </div>
          <div className="align-items search_btn">
            <button 
              type='button'
              className='common-search-btn mt-1'
              onClick={handleSearch}>
                Search
            </button>
          </div>
        </div>
        }
      </div>
      <div className="section_header mobile">
        <div className="flex-start">
          <div
            className="w-100 d-flex align-items-start top_input">
            <div className='mt-2 mr-10'>
              <GeoAlt size={24}/>
            </div>
            <Select
                closeMenuOnSelect={false}
                isOptionDisabled={() => places.length > 0 && places.length === 5}
                components={animatedComponents}
                isMulti
                options={modifiedStates}
                isClearable={true}
                placeholder="Start typing..."
                name="state"
                value={places}
                className="mobile-location-select"
                onChange={(e) => setPlaces(e)}
            />
          </div>
        </div>
        {!searchExcluded.includes(path) &&
        <div className="mt-10" style={{width:"100%"}}>
          <div className="top_input second flex-start">
            <div className="align-items">
              <Search size={24}/>
            </div>
            <Form.Control
              placeholder="Role, title, job description"
              onKeyUp={(e)=>{onSearch(e.target.value)}}
            />
          </div>
        </div>
        }
      </div>
    </div>
  )
}
