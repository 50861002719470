import React from 'react';
import PageTemplate from '../../templates/PageTemplate';
import OpportunitiesComponent from '../../components/Opportunities';
import { ExplorePagesWrapper } from '../../components/common/ExplorePagesWrapper';

export const OpportunitiesPage = ()=>{
  return (
    <PageTemplate>
      <ExplorePagesWrapper>
        <OpportunitiesComponent/>
      </ExplorePagesWrapper>
    </PageTemplate>
  )
}