import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FilterDropdowns from './FilterDropdowns';
import { Form } from 'react-bootstrap';
import OpportunityDetails from './Details';
import { OpportunityCard } from '../common/Cards';
import { FilterContext } from '../common/ExplorePagesWrapper';

import "./style.scss"
import { GetCategoryWithInterests } from '../../services/query/categories';
import {axiosClient} from "../../libs/axiosClient";

const OpportunitiesComponent = (props)=>{
  const {
    showInput,
    resourceID,
  } = {...props}

  const navigate = useNavigate();

  const { id: oppId } = useParams();

  const cardsGridRef = useRef(null);
  const sectionMainRef = useRef(null);
  const [collapse, setCollapse] = useState(false);
  const [rightModal, setRightModal] = useState(false);

  const [opps, setOpps] = useState([]);
  const [filterOpps, setFilterOpps] = useState([]);
  const [selectedOpp, setSelectedOpp] = useState({});
  const [filtered, setFiltered] = useState(false);
  const [categories, setCategories] = useState([]);
  const { searchFilters } = useContext(FilterContext)
  const {locations, search} = searchFilters

  const handleSelect = (id, opp) => {
    setSelectedOpp(opp);
    const children = cardsGridRef.current.children
    for (const element of children) {
      element.classList.remove('active');
    }
    children[id].classList.add('active');
    setRightModal(true);
    navigate(`/opportunities/${opp?.id}`)
  }
    
  const closeModal = () => {
    const children = cardsGridRef.current.children
    for (const element of children) {
      element.classList.remove('active')
    }
    setRightModal(false);
  }

  function filterByDatePosted(range) {
    let now = new Date();
    let filtered = [];
    for (let obj of opps) {
      let createdAt = new Date(obj.createdAt);
      let diff = (now - createdAt) / (1000 * 60 * 60);
      if (diff <= range) {
        filtered.push(obj);
      }
    }
    
    return filtered
  }

  function filterByDeadline(range) {
    let now = new Date();
    let filtered = [];
    
    for (let obj of opps) {
      let expiryDate = new Date(obj.expiry_date);
      let diff = (expiryDate - now) / (1000 * 60 * 60);
      
      if (diff <= range) {
        filtered.push(obj);
      }
    }
    
    return filtered
  }
  
  const setFilter = (key, value) => {
    if (key === 'category') { 
      let filteredOpps = []
      if (filtered){
        filteredOpps = opps.filter((elt) => elt?.category?.toLowerCase() === value.toLowerCase());
      }
      else {
        filteredOpps = filterOpps.filter((elt) => elt?.category?.toLowerCase() === value.toLowerCase());
      }
      setFilterOpps(filteredOpps);
      setFiltered(true)
    }
    else if (key === 'interests'){
      const category = categories.filter(elt => elt?.name === value)[0]
      let filteredOpps = opps.filter((elt)=> category.interests.some((interest) => elt?.interests.includes(interest)));

      setFilterOpps(filteredOpps);
    }
    else if (['datePosted','appDeadline'].includes(key)){
      let range = 0
      if (value === 'Today') range = 24
      else if (value.split(" ")[1] === '48') range = 48
      else range = Number.parseInt(value.split(" ")[1])*24

      let filtered = []

      if (key === 'datePosted') filtered = filterByDatePosted(range)
      else filtered = filterByDeadline(range)

      setFilterOpps(filtered)
    }
  };
  
  useEffect(() => {
    if (locations.length){
      let filteredOpps = opps.filter((elt) => locations.some(state => elt?.state?.includes(state)));
      setFilterOpps(filteredOpps);
    }
    else setFilterOpps(opps)
  }, [locations])

  useEffect(() => {
    if (search){
      const dataToSearch = opps;
      let nameSearch = dataToSearch.filter((elt) => elt?.name?.toLowerCase().includes(search.toLowerCase()));
      let descriptionSearch = dataToSearch.filter((elt) => elt?.description?.toLowerCase().includes(search.toLowerCase()));
      let roleSearch = dataToSearch.filter((elt) => elt?.school?.type?.toLowerCase().includes(search.toLowerCase()));
      let filtered = Array.from(new Set([...nameSearch, ...descriptionSearch, ...roleSearch]))
      setFilterOpps(filtered);
    }
    else {
      setFilterOpps(opps)
    }
  }, [search])

  const getCategories = async()=>{
    const cats = await GetCategoryWithInterests()
    cats.forEach((category)=>{
      category.interests = category?.interests.map(interest => interest?.name)
    })
    setCategories(cats)
  }

  const getOpportunities = async () => {
    try {
      const resp = await axiosClient.post("/opportunities", {
        limit: 20,
        review: true,
        fields: [
          "id",
          "description",
          "name",
          "category",
          "createdAt",
          "resourceID",
          "schoolID",
          "state",
          "expiry_date"
        ]
      });
      const data = resp?.data?.data;
      setOpps(data);
      setFilterOpps(data);
      if (oppId) {
        setRightModal(true);
        setSelectedOpp(data.find((elt) => elt.id === oppId));
      }
    } catch (err) {
      console.error(err);
    }
  }

  const getOpportunitiesByFilter = async () => {
    try {
      const resp = await axiosClient.post("/opportunities", {
        limit: 20,
        review: true,
        filter: {schoolID: {eq: resourceID}},
        fields: [
          "id",
          "description",
          "name",
          "category",
          "createdAt",
          "resourceID",
          "schoolID",
          "state",
          "expiry_date"
        ]
      });
      const data = resp?.data?.data;
      setOpps(data);
      setFilterOpps(data);
      if (oppId) {
        setRightModal(true);
        setSelectedOpp(data.find((elt) => elt.id === oppId));
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    setFilterOpps(opps);
    getCategories()
  }, [opps]);
  
  useEffect(() => {
    if (resourceID){
      getOpportunitiesByFilter();
    }
    else {
      getOpportunities();
    }
  }, [oppId]);
 
  return ( 
    <div className="flex-evenly">
      <div style={{width: "100%"}}>
        <div className='row align-items-start'>
          <div className='col p-0 mt-2'>
            <div 
              className={`d-flex gap-3 filter-drops-container custom-flex-wrap`}
              ref={sectionMainRef}
            >
            <FilterDropdowns setFilter={setFilter}/>
            </div>
          </div>
          {showInput &&
            <div className='col p-0 mt-2'>
              <div className=" d-flex gap-2" style={{ height: 80}}>
                <Form.Control
                  placeholder="Role, title, job description"
                  className='custom-form-input'
                  style={{maxWidth: 282}}
                />
                <button 
                  type='button'
                  className='common-search-btn'>
                    Search 
                </button>
              </div>
            </div>
          }
        </div>
        
        <div 
            className="d-flex custom-flex-wrap gap-2 justify-content-center justify-content-sm-start"
            ref={cardsGridRef}
          >
            {filterOpps?.map((opp, index) =>
              <OpportunityCard
                opp={opp}
                index={index}
                handleSelect={handleSelect}
                key={opp?.id}
              />
            )}
        </div>
      </div>
      <OpportunityDetails
        rightModal={rightModal}
        closeModal={closeModal}
        collapse={collapse}
        setCollapse={setCollapse}
        showIcon={showInput}
        opp={selectedOpp}
      />
    </div>
  );
}

export default OpportunitiesComponent