import React, { useRef, useState, useEffect, useContext } from 'react';

import { 
  GeoAlt,
  People
} from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom"
import { GetEducatorsBySchoolID } from '../../services/query/educators';
import { FilterContext } from '../common/ExplorePagesWrapper';
import { AllSchools } from '../../services/query/school';


export const MentorsComponent = (props)=>{
  const [organizations, SetOrganizations] = useState([])
  // const [mentors, SetMentors] = useState({})
  const [filterOrganizations, setFilterOrganizations] = useState([])
  const {searchFilters} = useContext(FilterContext) || {
    'locations': [],
    'search': ''
  }
  const {locations, search} = searchFilters
  const cardsGridRef = useRef(null)
  const navigate = useNavigate()

  const handleRoute = (mentorID) => {
    navigate(`/mentor-profile?id=${mentorID}`)
  }

  useEffect(() => {
    if (locations.length){
      let filtered = filterOrganizations.filter((elt) => locations.some(state => elt?.address?.toLowerCase().includes(state.toLowerCase())));
      setFilterOrganizations(filtered);
    }
    else setFilterOrganizations(organizations)
  }, [locations])

  useEffect(() => {
    if (search){
      let nameSearch = organizations.filter((elt) => elt?.name?.toLowerCase().includes(search.toLowerCase()));
      let emailSearch = organizations.filter((elt) => elt?.email?.toLowerCase().includes(search.toLowerCase()));
      let schoolSearch = organizations.filter((elt) => elt?.school?.name?.toLowerCase().includes(search.toLowerCase()));
      let filtered = Array.from(new Set([...nameSearch, ...emailSearch, ...schoolSearch]))
      setFilterOrganizations(filtered);
    }else {
      setFilterOrganizations(organizations)
    }
  }, [search])
  
  useEffect(() => {
    AllSchools('mentor').then(async (data) => { 
      let orgs = await data.reduce(async (prevProm, org) => {
        let mentors = await GetEducatorsBySchoolID(org?.id)
        let orgData = await prevProm
        const obj = {...org, mentors: mentors}
        orgData.push(obj)
        return orgData
      }, Promise.resolve([]))
      SetOrganizations(orgs); 
      setFilterOrganizations(orgs); 
    });
  }, []);

  return (
      <div 
          className="w-100 cards_grid organizations large d-flex custom-flex-wrap gap-2 justify-content-center justify-content-sm-start"
          ref={cardsGridRef}
        >
          {filterOrganizations && filterOrganizations.map((organization, index) => 
            <div 
              className='opportunity-card cursor-pointer' key={organization?.id}
              onClick={()=>{handleRoute(organization?.id)}}
            >
              <div className="d-flex align-items-start gap-2">
                <img 
                    src={organization?.image || require("../../assets/images/microsoft.png")}
                    alt={organization?.name}
                    style={{height: 40, width:40}}
                    className="rounded-circle object-fit-cover"
                  />
                <div className="d-flex flex-column">
                  <h6 className='mentor-school-name'>
                    {organization?.name}
                  </h6>
                  <div className="d-flex align-items-center mentor-address">
                    <GeoAlt size={13} fill="#969CA5" style={{marginLeft: -2}}/>
                    <div className="ms-1">
                      {organization?.address?.split(',')[1] || ""}
                    </div>
                  </div>
                </div>  
              </div>
              <div className="d-flex gap-1 mt-10 mentors-count">
                <People size={16} fill="#969CA5"/>
                <span className='mentors-count-number mr-1'>
                  {organization?.mentors?.length || 0}
                </span>
                {organization?.mentors?.length ? 'Mentor' : 'Mentors'}
              </div>
              <div className="w-100 pt-3 d-flex align-items-center resource-connections border-top">
                <div className='rounded-pill resource-connections-icon'>
                  <i className='fas fa-chain fa-fw text-white'></i>
                </div>
                <div className="align-items ml-10">
                  Connected to
                  <span className='ps-1 resource-connections-count'>
                    {organization?.connections?.length || 0} 
                    <span className='ps-1'>
                      {organization?.connections?.length > 1 ? 'Youths' : 'Youth'}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}
      </div>
  );
}
